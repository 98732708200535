<template>
  <v-container fluid>
    <v-btn ref="hiddenBtn" v-show="false"></v-btn>
    <v-card tile class="mt-3">
      <v-card-title class="text-uppercase overline">
        <v-btn class="mr-3" @click="$router.push('/')" text>
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        {{
          event
            ? `${event.description} , ${formatDate(
                event.startDate,
                6
              )} - ${formatDate(event.endDate, 6)}, ${event.location}`
            : "" | capitalize("also this")
        }}

        <v-spacer />

        <v-btn text @click="search = !search" :color="search ? 'info' : ''">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn text @click="fetchDelegates()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn
          text
          @click="toggleFullscreen()"
          :color="fullscreen ? 'info' : ''"
        >
          <v-icon
            >{{ fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
        </v-btn>

        <v-btn text @click="syncAttendance()">
          <v-icon>mdi-web-sync</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="search" class="grey lighten-3 px-10">
        <div class="d-flex justify-end">
          <v-btn @click="search = false" color="info" x-small text class="ma-3">
            hide search
          </v-btn>
        </div>

        <v-text-field
          label=""
          color="primary"
          v-model="searchTerm"
          placeholder="Search by ID Number"
          outlined
          @input="searchDelegate"
          hide-details
          autofocus
          elevation-12
        />
      </v-card-text>
      <v-row class="pa-5">
        <v-col cols="12" md="3">
          <v-card elevation="3" dark color="primary">
            <div class="ml-2">
              Day {{ day }} of {{ event ? event.eventDurationDays : "" }}
            </div>

            <v-card-actions class="overline">
              All Delegates
              <v-spacer />
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchDelegates"
                    style="cursor: pointer"
                    color="primary lighten-5"
                    text-color="primary darken-4"
                  >
                    {{ delegates.total }}
                  </v-chip>
                </template>
              </v-tooltip>
            </v-card-actions>
            <v-divider />

            <v-card-actions class="overline">
              Slots Available
              <v-spacer />
              <v-chip
                dark
                style="cursor: pointer"
                color="primary lighten-5"
                text-color="primary darken-4"
              >
                {{ event ? event.slotsAvailable : 0 }}
              </v-chip>
            </v-card-actions>

            <v-divider />

<!--            <v-card-actions class="overline">-->
<!--              Checked In-->
<!--              <v-spacer />-->
<!--              <v-tooltip right>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-chip-->
<!--                    dark-->
<!--                    style="cursor: pointer"-->
<!--                    @click="filterDelegates('isAttendedToday')"-->
<!--                    color="primary lighten-5"-->
<!--                    text-color="primary darken-4"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                  >-->
<!--                    {{ checkedIn?.length }}-->
<!--                  </v-chip>-->
<!--                </template>-->
<!--                <span>Filter Checked In</span>-->
<!--              </v-tooltip>-->
<!--            </v-card-actions>-->

            <v-divider />

            <!-- <v-card-actions class="overline">
              Total Registered
              <v-spacer />
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    dark
                    style="cursor: pointer"
                    @click="filterDelegates('isRegistered')"
                    color="primary lighten-5"
                    text-color="primary darken-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ totalRegistered.length }}
                  </v-chip>
                </template>
                <span>Filter Total Registered</span>
              </v-tooltip>
            </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col cols="12" md="9">
          <div class="d-flex justify-space-between">
            <span class="text-lg-h5 font-weight-bold mb-0"
              >Delegate Register</span
            >
            <v-spacer />
            <v-btn color="primary text-capitalize" @click="addDelegate">
              <v-icon left>mdi-plus</v-icon>
              New Delegate
            </v-btn>
          </div>
          <div>
            <v-responsive>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="header in headers" :key="header.text">
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in delegates.data" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.eMail }}</td>
                      <td>{{ item.identificationDocumentNo }}</td>
                      <td>{{ item.phoneNo }}</td>
                      <td>
                        <v-chip
                          x-small
                          color="secondary darken-2"
                          text-color="white"
                        >
                          {{ getAttendanceLength(item.attendance) }}
                          /
                          {{ event ? event.eventDurationDays : 0 }}
                        </v-chip>
                      </td>
                      <td>
                        <span> {{ totalAttendeesPay(item) | currency }} </span>
                      </td>
                      <td>
                        <v-btn-toggle>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="viewItem(item)"
                                v-bind="attrs"
                                v-on="on"
                                small
                                elevation="0"
                                class="primary lighten-4 primary--text text--darken-4 text-capitalize"
                              >
                                <v-icon left class="mx-1">mdi-eye</v-icon>
                              </v-btn>
                            </template>
                            <span>View/Edit Delegate</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="checkinDelegate(item)"
                                v-bind="attrs"
                                v-on="on"
                                small
                                elevation="0"
                                class="primary lighten-4 primary--text text--darken-4 text-capitalize"
                              >
                                <v-icon left class="mx-1"
                                  >mdi-fingerprint</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Checkin Delegate</span>
                          </v-tooltip>
                        </v-btn-toggle>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <PaginationComponent
                v-if="delegates.total"
                :current-cursor="delegates.currentCursor"
                :next-cursor="delegates.nextCursor"
                @pagination-change-page="fetchDelegates"
              />
            </v-responsive>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <DelegateCard />
  </v-container>
</template>
<script>
import DateMixin from "@/mixins/DateMixin";
import DelegateCard from "./DelegateCard.vue";
import DashboardMixins from "../DashboardMixins";
import moment from "moment";
import PaginationComponent from "../../../components/PaginationComponent.vue";
import {helper} from "@/utils";

export default {
  name: "DelegateList",
  mixins: [DateMixin, DashboardMixins],
  components: { PaginationComponent, DelegateCard },
  data: function () {
    return {
      search: false,
      searchTerm: "",
      dialog: false,
      delegateFilter: [],
      isFilter: false,
      isRegistered: true,
      fullscreen: false,
      debouncedSearchDelegate: () => {},
    };
  },
  mounted() {
    this.$store.dispatch("Events/getEvent", this.$route.params.event);
    this.fetchDelegates();
    this.debouncedSearchDelegate = helper.debounce(this.fetchDelegates, 1000);
  },
  computed: {
    loading: function () {
      return this.$store.getters["Dashboard/dashboardGetters"]("loading");
    },
    delegates: function () {
      return this.$store.getters["Dashboard/dashboardGetters"]("delegates");
    },
    event: function () {
      return this.$store.getters["Events/eventsGetters"]("event");
    },
    day() {
      if (!this.event) return 0;
      let diff = moment().diff(moment(this.event.startDate), "days");
      if (diff < 0) return 0;
      if (diff > this.event.eventDurationDays)
        return this.event.eventDurationDays;
      return diff + 1;
    },
    headers: function () {
      return [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "eMail" },
        { text: "Identification No.", value: "identificationDocumentNo" },
        { text: "Phone No.", value: "phoneNo" },
        { text: "Attendance", value: "attendance" },
        {
          text: "Total Delegate Pay",
          value: "attendees",
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    totalAttendeesPay(delegate) {
      return delegate.attendees
        .filter((a) => a.eventNo === this.$route.params.event)
        .reduce((a, b) => {
          return a + b.delegatePay;
        }, 0);
    },
    viewItem: function (item) {
      this.$router.push({
        name: "DelegateCard",
        params: { event: this.$route.params.event, no: item.no },
        query: {
          isEdit: btoa(true),
        },
      });
    },

    checkinDelegate: function (item) {
      window.location.href = `${window.location.origin}/dashboard/delegates/${this.$route.params.event}/checkin?identificationDocumentNo=${item.identificationDocumentNo}`;
    },
    addDelegate: function () {
      this.$router.push({
        name: "DelegateCard",
        params: {
          event: this.$route.params.event,
        },
      });
    },
    searchDelegate: function () {
      this.debouncedSearchDelegate();
    },
    toggleFullscreen: function () {
      if (this.fullscreen) {
        document.exitFullscreen();
        this.fullscreen = false;
      } else {
        document.documentElement.requestFullscreen();
        this.fullscreen = true;
      }
    },
    syncAttendance() {
      this.$store.dispatch("Dashboard/syncAttendance", {
        ...this.$store.getters["Dashboard/dashboardGetters"]("request_stack"),
      });
      this.$store.dispatch("Dashboard/syncDelegates", {
        ...this.$store.getters["Dashboard/dashboardGetters"]("delegates"),
      });
    },
    getAttendanceLength(attendance) {
      return attendance.filter((a) => a.eventNo === this.$route.params.event)
        .length;
    },
    refreshFingerprint() {
      window.location.reload();
    },

    fetchDelegates(cursor = null) {
      this.$store.dispatch("Dashboard/getDelegates", {
        cursor: cursor || "",
        identificationDocumentNo: this.searchTerm || "",
      });
    },
  },
};
</script>

<style>
.search-delegate > .v-input__control {
  padding-top: 0.8%;
  margin-right: -1%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search-delegate > .v-input__control > .v-input__slot {
  min-height: 2.5rem !important;
}

.search-btn.v-btn:not(.v-btn--round).v-size--default {
  margin: 0 !important;
  min-height: 2.5rem;
}

.search-btn.v-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
