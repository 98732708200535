<template>
  <v-card flat>
    <v-card-title class="ml-3"> Approval Entries </v-card-title>
    <v-timeline dense class="ml-n3">
      <v-timeline-item
        v-for="(item, i) in event.approvalEntry"
        :key="i"
        :color="getColor(item.status).icon_color"
        small
        fill-dot
        v-show="item.status !== 'Canceled'"
        :icon="getColor(item.status).icon"
      >
        <div v-if="item.status !== 'Canceled'" class="d-flex ml-n10">
          <div class="">
            <v-list-item dense>
              <v-list-item-avatar
                :color="getColor(item.status).icon_color"
                style="color: rgb(0, 68, 139)"
                size="34"
              >
                {{ getEmployeeInitials(item.approverID) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="d-flex">
                    <div>
                      {{ getEmployeeName(item.approverID) }}
                    </div>
                    <v-chip
                      label
                      class="mx-1"
                      :color="getColor(item.status).icon_color"
                      x-small
                      ><span class="view-page">{{ item.status }}</span>
                    </v-chip>
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle class="">
                  {{ getEmployeeEmail(item.approverID) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div class="comments">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Comment
                </v-list-item-title>
                <v-card
                  flat
                  class="text--secondary"
                  v-for="(item, i) in getComment(item)"
                  :key="i"
                >
                  {{ item.comment }}
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>
<script>
import { helper } from "@/utils";
export default {
  name: "ApprovalEntries",
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    allEmployees() {
      return this.$store.getters["Setup/employees"];
    },
  },

  methods: {
    getEmployeeName(id) {
      const employee = this.allEmployees.find((el) => el.user_id === id);
      if (employee) {
        return [employee.first_name, employee.last_name].join(" ");
      }
      return "-";
    },

    getEmployeeEmail: function (id) {
      const employee = this.allEmployees.find((el) => el.user_id === id);
      if (employee) {
        return employee.email;
      }
      return "";
    },
    getEmployeeInitials(id) {
      const employee = this.allEmployees.find((el) => el.user_id === id);
      if (employee) {
        return [
          employee.first_name.charAt(0),
          employee.last_name.charAt(0),
        ].join("");
      }
      return "-";
    },
    getEmployeeByID(id) {
      return this.allEmployees.filter((el) => el.user_id === id).shift();
    },
    getColor(status) {
      return helper.getColorGlobal(status);
    },
    getEntryDateFormat(date) {
      return helper.getEntryDateFormat(date);
    },
    splitApproverID(id) {
      console.log(id);
      return id + id.split("\\")[1][0];
    },
    ApproverID(id) {
      console.log(id);
      return id + id.split("-")[1][0];
    },
    getComment(comment) {
      const user = comment.approverID;
      const commented = comment.approvalComments
        ? comment.approvalComments.filter((d) => d.userID === user)
        : [];
      return commented;
    },
  },
};
</script>
<style scoped>
/* @import url("../styles/styles.css"); */
</style>
