export default {
  DELEGATES: (params) =>
    `delegates?cursor=${params.cursor || ""}&identificationDocumentNo=${
      params.identificationDocumentNo || ""
    }`,
  GET_DELEGATE: (params) => `delegates/show?${getParams(params)}`,
  SEARCH_DELEGATE: (params) => `delegates/search?${getParams(params)}`,
  SAVE_DELEGATE: "delegates/save",
  UPDATE_DELEGATE: "delegates/update",
  DELETE_DELEGATE: "delegates/delete",
  VERIFY_DELEGATE_FINGERPRINT: "biometric/verifyDelegate",
  REGISTER_FINGERPRINT: "biometric/register",
  MANUAL_CHECKIN: "delegates/manualCheckin",
  SYNC_ATTENDANCE: "delegates/syncAttendance",
  getReport: ({ no, entity }) => `/delegates/reports/${entity}?eventNo=${no}`,
  editAttendeePay: "delegates/editAttendeePay",
  addAttendeePay: "delegates/addAttendeePay",
  syncDelegates: "delegates/syncDelegates",
};

function getParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
