<script>
export default {
  name: "PayAttendee",
  props: ["dialog", "delegateNo", "attendeeData"],

  data: function () {
    return {
      editing: false,
      editingIndex: -1,
    };
  },

  mounted() {
    Event.$on("paymentSuccessful", () => {
      this.closeDialog();
    });
  },

  computed: {
    attendee: function () {
      return (
        this.attendeeData ??
        this.$store.getters["Dashboard/dashboardGetters"]("attendee")
      );
    },
    headers: function () {
      return [
        { text: "Package Code", value: "packageCode" },
        { text: "Description", value: "description" },
        { text: "Amount", value: "amount" },
        { text: "Actions", value: "actions" },
      ];
    },
    defaultLineItem: function () {
      return {
        delegateNo: this.delegateNo,
        packageCode: "",
        packageLineNo: 0,
        eventNo: "",
        description: "",
        amount: "",
      };
    },

    event: function () {
      return this.$store.getters["Events/eventsGetters"]("event");
    },

    packages: function () {
      return this.event
        ? this.event.eventPackages.filter(
            (p) => p.packageCode !== this.attendee.attendeePay.packageCode
          )
        : [];
    },
  },

  methods: {
    pay: function () {
      const data = this.attendee.attendeePay.map((item) => {
        const { lineNo, ...data } = item;
        console.log(lineNo);
        return {
          ...data,
          amount: parseInt(data.amount),
        };
      });

      this.$store.dispatch("Dashboard/addAttendeePay", { attendeePays: data });
    },

    closeDialog: function () {
      this.$emit("close");
    },

    closeEditing: function (item) {
      item.delegatePay = parseInt(item.delegatePay);
      this.editItem(-1);
    },
    newLine: function () {
      const addObj = Object.assign({}, this.defaultLineItem);
      addObj.id = this.attendee.attendeePay.length + 1;
      this.attendee.attendeePay.unshift(addObj);
      this.editItem(0);
    },
    editItem: function (index) {
      this.editingIndex = -1;
      this.editingIndex = index;
    },
    addDescription: function (event, index) {
      this.attendee.attendeePay[index].packageCode = event.packageCode;
      this.attendee.attendeePay[index].description = event.description;
      this.attendee.attendeePay[index].eventNo = event.eventNo;
      this.attendee.attendeePay[index].packageLineNo = event.lineNo;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="89%">
    <v-card>
      <v-card-title>Attendee's Pay</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6" md="3">
            <label for="delegate-number" class="text--disabled">
              Delegate Number:
            </label>
            <v-text-field
              disabled
              id="delegate-number"
              solo-inverted
              flat
              dense
              v-model="attendee.delegateNo"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <label for="delegate-name" class="text--disabled">
              Delegate Name:
            </label>
            <v-text-field
              disabled
              id="delegate-name"
              solo-inverted
              flat
              dense
              v-model="attendee.name"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <label for="date" class="text--disabled">Attendance Date: </label>
            <v-text-field
              disabled
              id="date"
              solo-inverted
              flat
              dense
              v-model="attendee.attendanceDate"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <label for="delegate-pay" class="text--disabled">
              Delegate Pay:
            </label>
            <v-text-field
              disabled
              solo-inverted
              flat
              id="delegate-pay"
              dense
              prefix="KES"
              v-model="attendee.delegatePay"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="newLine">
              <v-icon>mdi-plus</v-icon>
              add package
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="attendee.attendeePay">
          <!--eslint-disable-next-line -->
          <template v-slot:item.packageCode="{ item,index }">
            <div class="py-auto">
              <v-autocomplete
                :items="packages"
                :item-text="(item) => item.description"
                :item-value="(item) => item.lineNo"
                v-if="editingIndex === index"
                v-model="item.lineNo"
                @input="addDescription($event, index)"
                placeholder="Package"
                filled
                dense
                class="mt-4"
                return-object
              >
              </v-autocomplete>
              <span v-else>{{ item.packageCode }}</span>
            </div>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.amount="{ item, index }">
            <div class="py-auto">
              <v-text-field
                v-if="editingIndex === index"
                v-model="item.amount"
                placeholder="Delegate Pay"
                dense
                filled
                clearable
                class="mt-4"
              >
              </v-text-field>
              <span v-else>{{ item.amount | currencyFormat }}</span>
            </div>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.actions="{ item, index }">
            <v-icon
              @click="editItem(index)"
              v-if="editingIndex !== index"
              color="blue"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="closeEditing(item)"
              v-if="editingIndex === index"
              color="success"
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          depressed
          @click="closeDialog"
          color="red lighten-4 red--text text--darken-4 text-capitalize"
        >
          <v-icon class="mr-2">mdi-close</v-icon>
          Close
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          @click="pay()"
          color="green lighten-4 green--text text--darken-4 text-capitalize"
        >
          <v-icon class="mr-2">mdi-content-save</v-icon>
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
