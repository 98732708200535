<template>
  <v-container fluid>
    <v-card tile class="my-3">
      <v-card-title class="text-uppercase overline">
        <v-btn class="mr-3" @click="$router.back()" text>
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        {{
          event
            ? `${event.description} , ${formatDate(
                event.startDate,
                6
              )} - ${formatDate(event.endDate, 6)}, ${event.location}`
            : "" | capitalize("also this")
        }}

        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" text @click="refreshPage()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh page</span>
        </v-tooltip>

        <v-btn
          text
          @click="toggleFullscreen()"
          :color="fullscreen ? 'info' : ''"
        >
          <v-icon>{{
            fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen"
          }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="grey lighten-3 px-10 py-5">
        <v-text-field
          label=""
          color="primary"
          v-model="searchTerm"
          placeholder="Search by ID Number"
          outlined
          @keyup.enter="searchDelegate"
          hide-details
          autofocus
          elevation-12
        />
      </v-card-text>
    </v-card>

    <delegate v-if="checkinDelegate" @close="initPage" />
  </v-container>
</template>
<script>
import DateMixin from "@/mixins/DateMixin";
import DashboardMixins from "../DashboardMixins";
import Delegate from "@/modules/dashboard/components/Delegate.vue";

export default {
  name: "Checkin",
  components: { Delegate },
  mixins: [DateMixin, DashboardMixins],
  data: function () {
    return {
      searchTerm: "",
      checkinDelegate: false,
      fullscreen: false,
    };
  },
  mounted() {
    Event.$on("scanDelegateFingerprint", () => {
      this.$scanner.scan("verify");
      this.checkinDelegate = true;
    });

    this.$store.dispatch("Events/getEvent", this.$route.params.event);

    if (this.$route.query && this.$route.query.identificationDocumentNo) {
      this.searchTerm = this.$route.query.identificationDocumentNo;
      this.searchDelegate();
    }
  },
  computed: {
    event() {
      return this.$store.getters["Events/eventsGetters"]("event");
    },
    delegate: function () {
      return this.$store.getters["Dashboard/dashboardGetters"]("delegate");
    },
  },
  methods: {
    searchDelegate: function () {
      if (this.searchTerm.length) {
        this.$store.dispatch("Dashboard/searchDelegate", {
          identificationDocumentNo: this.searchTerm,
        });
      } else {
        this.$store.commit("Dashboard/MUTATE", {
          state: "delegate",
          data: {},
        });
      }
    },
    toggleFullscreen: function () {
      if (this.fullscreen) {
        document.exitFullscreen();
        this.fullscreen = false;
      } else {
        document.documentElement.requestFullscreen();
        this.fullscreen = true;
      }
    },
    refreshPage: function () {
      window.location.reload();
    },

    initPage: function () {
      this.checkinDelegate = false;
      window.location.href = `${window.location.origin}/dashboard/delegates/${this.$route.params.event}/checkin`;
    },
  },
};
</script>

<style>
.search-delegate > .v-input__control {
  padding-top: 0.8%;
  margin-right: -1%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search-delegate > .v-input__control > .v-input__slot {
  min-height: 2.5rem !important;
}

.search-btn.v-btn:not(.v-btn--round).v-size--default {
  margin: 0 !important;
  min-height: 2.5rem;
}
.search-btn.v-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
