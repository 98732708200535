<template>
  <v-container>
    <v-sheet min-height="70vh" rounded="lg" class="pa-5">
      <v-simple-table>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="mr-2">Events</v-toolbar-title>
            <v-spacer />
            <v-text-field
              class="mt-2"
              v-model="term"
              placeholder="Search by event name..."
              dense
              append-icon="mdi-magnify"
              @input="searchEvents"
            >
              <template v-slot:append>
                <v-icon left>mdi-search</v-icon>
              </template>
            </v-text-field>
            <v-spacer />
            <v-btn class="primary" @click="addEvent" v-if="user.is_admin">
              <v-icon left>mdi-plus</v-icon>
              Add Event
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="header in headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in events.data" :key="item.id">
              <td>{{ item.description }}</td>
              <td>
                <v-chip
                  :class="`${
                    item.eventType === 'Budgeted' ? 'primary' : 'secondary'
                  }`"
                  label
                  >{{ item.eventType }}</v-chip
                >
              </td>
              <td>
                <v-chip :class="`${statusColor(item.status)}`" label>{{
                  item.status
                }}</v-chip>
              </td>
              <td>{{ item.location }}</td>
              <td>{{ formatDate(item.startDate, 6) }}</td>
              <td>{{ formatDate(item.endDate, 6) }}</td>
              <td>
                <div class="d-flex flex-row">
                  <v-btn
                    class="green text-capitalize mx-2 white--text"
                    @click="activateEvent(item)"
                    v-if="
                      item.status === 'Released' &&
                      item.eventStatus !== 'Active'
                    "
                  >
                    Activate Event
                  </v-btn>
                  <v-btn @click="viewEvent(item)" class="mr-4" color="teal">
                    View
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                  <v-btn
                    :to="{ name: 'EventView', params: { code: item.no } }"
                    v-if="item.eventStatus === 'Active'"
                    color="primary"
                  >
                    Checkin Attendees
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                  <v-btn
                    @click="deleteEvent(item)"
                    v-if="item.status === 'Open'"
                    class="mx-2 error"
                  >
                    Delete
                    <v-icon right>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <PaginationComponent
        v-if="events.total"
        :current-cursor="events.currentCursor"
        :next-cursor="events.nextCursor"
        @pagination-change-page="fetchEvents"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import DateMixin from "@/mixins/DateMixin";
import { AuthService } from "@/modules/auth";
import { helper } from "@/utils";
import PaginationComponent from "@/components/PaginationComponent.vue";
export default {
  name: "Dashboard",
  components: { PaginationComponent },
  mixins: [DateMixin],
  data: function () {
    return {
      term: "",
      headers: [
        {
          text: "Event name",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Type", value: "eventType" },
        { text: "Status", value: "status" },
        { text: "Location", value: "location" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Action", value: "action" },
      ],
      items: [],
      debouncedSearchEvents: () => {},
    };
  },
  mounted() {
    this.fetchEvents();
    this.debouncedSearchEvents = helper.debounce(this.fetchEvents, 1000);
  },
  computed: {
    user() {
      return AuthService.user;
    },
    events() {
      return this.$store.getters["Events/eventsGetters"]("events");
    },
  },
  methods: {
    fetchEvents(cursor = null) {
      this.$store.dispatch("Events/getEvents", {
        cursor: cursor || "",
        employee_no: AuthService.user?.employee
          ? AuthService.user.employee?.number
          : "",
        description: this.term,
      });
    },
    searchEvents() {
      this.debouncedSearchEvents();
    },
    addEvent() {
      this.$router.push({ name: "EventCard" });
    },
    viewEvent(event) {
      this.$router.push({ name: "EventCard", params: { no: event.no } });
    },
    deleteEvent(event) {
      this.$confirm.show({
        title: "Delete Event",
        text: "Are you sure you want to delete this event?",
        onConfirm: () => {
          this.$store.dispatch("Events/deleteEvent", event);
        },
      });
    },
    activateEvent(event) {
      this.$confirm.show({
        title: "Activate Event",
        text: "Are you sure you want to activate this event?",
        onConfirm: () => {
          this.$store.dispatch("Events/activateEvent", { docNo: event.no });
        },
      });
    },
    statusColor(status) {
      return helper.status(status);
    },
  },
};
</script>

<style scoped></style>
