export default {
  terms: "company/terms",
  requirements: "application/requirements",
  login: `auth/login`,
  socialLoginRedirect: (provider) => `auth/${provider}/redirect`,
  socialLoginCallback: (provider) => `auth/${provider}/callback`,
  autoLogin: `auth/auto-login`,
  register: `auth/register`,
  verify: `auth/verify`,
  passwordReset: `auth/password-reset`,
  password: `auth/password`,
  logout: `auth/logout`,
  user: `auth/user`,
  activate: `profile/activation/request`,
  searchMember: (MemberNo) => `profile/search/member?MemberNo=${MemberNo}`,
};
