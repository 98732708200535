<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
    @keypress.enter="login"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <v-img
            :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
            height="100"
            contain
            class="mb-5"
          />

          <div v-if="checkingIfAuthenticated" class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <template v-else>
            <v-card-title
              class="text-h5 d-flex flex-row justify-center text-capitalize"
            >
              Login to {{ appName.toLowerCase() }}
            </v-card-title>

            <v-card-text class="mt-4 pb-0">
              <v-alert
                outlined
                v-if="$store.getters['Auth/alert'].status"
                dense
                border="left"
                :type="
                  $store.getters['Auth/alert'].status === 'success'
                    ? 'success'
                    : 'error'
                "
                class="mb-10"
              >
                {{ $store.getters["Auth/alert"].message }}
              </v-alert>

              <v-text-field
                dense
                outlined
                label="Email Address"
                placeholder="Enter your Email Address"
                v-model="formData.email"
                ref="email"
                :rules="rules.email"
              />

              <v-text-field
                dense
                outlined
                label="Password"
                placeholder="Enter your password"
                v-model="formData.password"
                ref="password"
                :rules="rules.password"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
            </v-card-text>

            <v-btn text :to="{ name: 'forgotPassword' }">
              Forgot password?</v-btn
            >

            <v-card-actions class="d-flex flex-column">
              <v-btn @click="login" color="primary" block>Login</v-btn>
              <div class="my-2">-- OR --</div>
              <v-btn
                @click="socialLogin('microsoft')"
                color="primary"
                block
                outlined
                class="ml-0"
              >
                <v-icon>mdi-microsoft-dynamics-365</v-icon>
                Login with Microsoft
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";
import { appName } from "@/environment/environment";

export default {
  name: "Login",
  mixins: [RouterMixin],
  data: function () {
    return {
      formData: {
        email: "",
        password: "",
      },
      showPassword: false,
    };
  },

  mounted() {
    if (this.$route.query.uid) {
      this.$store.dispatch("Auth/autoLogin", {
        email: this.decodeRoute(this.$route.query.uid),
      });
    }
  },

  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [(v) => !!v || "Password is required"],
      };
    },

    appName() {
      return appName;
    },

    checkingIfAuthenticated: function () {
      return this.$store.getters["Auth/checkingIfAuthenticatedStatus"];
    },
  },

  methods: {
    login: function () {
      let isValid = true;
      for (const key in this.formData) {
        if (this.$refs[key])
          isValid = isValid ? this.$refs[key].validate(true) : false;
      }

      if (isValid) this.$store.dispatch("Auth/login", { ...this.formData });
    },

    socialLogin: function (provider = "microsoft") {
      this.$store.dispatch("Auth/socialLoginRedirect", provider);
    },
  },
};
</script>

<style scoped></style>
