<template>
  <v-container
    fluid
    class="fill-height d-flex flex-row align-center"
    style="background-color: rgba(255, 255, 255, 0.65)"
  >
    <v-row>
      <v-col md="4" sm="12" offset-md="4">
        <v-card class="pa-5">
          <v-img
            :src="`data:image/png;base64,${$store.getters['Setup/companyInformation'].logo}`"
            height="100"
            contain
            class="mb-5"
          />

          <div v-if="checkingIfAuthenticated" class="text-center">
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <template v-else>
            <v-card-text class="mt-4 pb-0">
              <v-alert
                outlined
                v-if="$store.getters['Auth/alert'].status"
                dense
                border="left"
                :type="
                  $store.getters['Auth/alert'].status === 'success'
                    ? 'success'
                    : 'error'
                "
                class="mb-10"
              >
                {{ $store.getters["Auth/alert"].message }}
              </v-alert>
            </v-card-text>

            <v-card-actions class="d-flex flex-column">
              <v-btn href="/auth" color="primary" block>Go Back</v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMixin from "../../../router/RouterMixin";

export default {
  name: "SocialAuthCallback",
  mixins: [RouterMixin],

  mounted() {
    if (this.$route.query.code) {
      this.$store.dispatch("Auth/socialLoginCallback", {
        code: this.$route.query.code,
        provider: "microsoft",
      });
    }
  },

  computed: {
    checkingIfAuthenticated: function () {
      return this.$store.getters["Auth/checkingIfAuthenticatedStatus"];
    },
  },
};
</script>
