<script>
export default {
  name: "SigmaPaginationWrapper",
  props: {
    currentCursor: {
      type: String,
      default: null,
    },
    nextCursor: {
      type: String,
      default: null,
    },
  },

  data: function () {
    return {
      currentCursors: [],
    };
  },

  computed: {
    isFirstPage() {
      return !this.currentCursor;
    },

    isLastPage() {
      return !this.nextCursor;
    },

    previousCursor: function () {
      const index = this.currentCursors.length - 2;
      return index >= 0 ? this.currentCursors[index] : null;
    },
  },

  watch: {
    currentCursor: {
      handler(newValue) {
        if (!this.currentCursors.includes(newValue)) {
          this.currentCursors.push(newValue);
        }
      },
      immediate: true,
    },

    nextCursor: function (newValue) {
      if (newValue) {
        const index = this.currentCursors.indexOf(newValue);
        if (index !== -1) {
          this.currentCursors.splice(index, 1);
        }
      }
    },
  },

  methods: {
    goToNext() {
      if (!this.isLastPage) {
        this.$emit("pagination-change-page", this.nextCursor);
      }
    },
    goToPrevious() {
      if (!this.isFirstPage) {
        this.$emit("pagination-change-page", this.previousCursor);
      }
    },
  },

  render: function () {
    return this.$scopedSlots.default({
      computed: {
        isFirstPage: this.isFirstPage,
        isLastPage: this.isLastPage,
      },
      prevButtonEvents: {
        click: (e) => {
          e.preventDefault();
          this.goToPrevious();
        },
      },
      nextButtonEvents: {
        click: (e) => {
          e.preventDefault();
          this.goToNext();
        },
      },
    });
  },
};
</script>
