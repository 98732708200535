export default {
  namespaced: true,
  state: {
    deviceConnected: false,
  },
  mutations: {
    SET_DEVICE_CONNECTED: (state, payload) => {
      state.deviceConnected = payload;
    },
  },
  getters: {
    deviceConnected: (state) => state.deviceConnected,
  },
  actions: {
    setFingerprint({ dispatch }, payload) {
      dispatch("Dashboard/setFingerprint", payload, { root: true });
    },
  },
};
